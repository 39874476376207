import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import style from './WhatsAppLink.module.scss';
import { TicketContext } from '../../contexts/TicketContext';
import { ActionContainer } from '../ActionContainer/ActionContainer';
import WhatsAppButton from '../WhatsAppButton/WhatsAppButton';


const WhatsAppLink: FC = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { ticket } = useContext(TicketContext);

  if (!ticket) {
    return null;
  }

  return (
    <ActionContainer bgClassName={style.containerBG} innerStyle={style.containerInner}>
      <div className={style.row}>
        <span className={style.header}>{t('contact:chatWithWhatsApp')}</span>
        <img width={64} height={64} alt="whatsapp" src="/assets/green_callouts.png" />
      </div>
      <p className={style.p}><Trans i18nKey="contact:chatWithWhatsAppDescription"/></p>
      <WhatsAppButton variant="regularButton" />
    </ActionContainer>
  );
};

export default WhatsAppLink;
