import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { StylesheetContext } from '../../contexts/StylesheetContext';
import Button from '../Button';

interface WhatsButton {
  variant: 'customButton';
  customButtonClassName: string;
  text: string;
}

interface CustomButton {
  variant: 'regularButton';
}

interface RegularProps {
  regularButtonClassname?: string;
}

export type WhatsAppButtonProps = (CustomButton | WhatsButton) & RegularProps;


const WhatsAppButton: FC<WhatsAppButtonProps> = (props): JSX.Element | null => {
  const { stylesheet } = useContext(StylesheetContext);
  const { t } = useTranslation();

  const emptySpaceCondition = /\s/g;

  // In case there is a misconfigurion on backend this removes spaces between numbers if present
  const formattedContactNumber = (stylesheet.contact.whatsappNumber || '').replace(emptySpaceCondition, '');

  const openWhatsApp = useCallback(() => {
    window.open(`https://wa.me/${formattedContactNumber}`, '_blank');
  }, [formattedContactNumber]);

  const whatsappIcon = <img alt="whatsapp" src="/assets/whatsapp.svg" />;

  return (
    <>
      {props.variant !== 'regularButton' && (
        <button
          aria-label={props.text}
          className={props.customButtonClassName}
          onClick={openWhatsApp}
        >
          {whatsappIcon}
          {props.text}
        </button>
      )}
      <Button
        img={whatsappIcon}
        onClick={openWhatsApp}
        text={t('contact:startChat')}
        size="full"
        className={props.regularButtonClassname}
      />
    </>
  );
};

export default WhatsAppButton;
