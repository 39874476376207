import { FC, useContext } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import style from './ChatLink.module.scss';
import { TicketContext } from '../../contexts/TicketContext';
import { startChat } from '../../services/ChatService';
import Button from '../Button/Button';
import { Tag } from '../Tag/Tag';
import { ActionContainer } from '../ActionContainer/ActionContainer';

const ChatLink: FC = (): JSX.Element | null => {
  const { ticket } = useContext(TicketContext);

  if (!ticket) {
    return null;
  }

  return (
    <ActionContainer bgClassName={style.containerBG}>
      <img width={64} height={64} alt="whatsapp" src="/assets/blue_callouts.png" />

      <div className={style.content}>
        <div className={style.headerRow}>
          <span className={style.header}>{t('contact:chatWithSF')}</span>
          <Tag
            text={t('contact:fastest')}
            icon={<img height={14} alt={t('contact:fastest')} src="/assets/quick_watch.png" />}
          />
        </div>

        <p className={style.p}><Trans>{t('contact:chatWithSFDescription')}</Trans></p>
        <Button
          onClick={startChat}
          text={t('contact:startChat')}
        />
      </div>
    </ActionContainer>
  );
};

export default ChatLink;
