import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './ContactChatContainer.module.scss';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { Separator } from '../Separator/Separator';
import { Tag } from '../Tag/Tag';
import WhatsAppButton from '../WhatsAppButton/WhatsAppButton';

import { isSFChatAvailable, isWhatsappAvailable, startChat } from '../../services/ChatService';

export const ContactChatContainer: FC= () => {
  const { stylesheet } = useContext(StylesheetContext);
  const { t } = useTranslation();
  const isChatAvailable = isSFChatAvailable();
  const isWhatsAppAvailable = isWhatsappAvailable(stylesheet);

  const isDesktopOnly = isChatAvailable && !isWhatsAppAvailable;

  if (!isChatAvailable && !isWhatsAppAvailable) {
    return null;
  }

  return (
    <>
      <div className={classNames(styles.container, isDesktopOnly && styles.hideTillMobile)}>
        <div className={styles.header}>
          <h2>{t('contact:chatWithSF')}</h2>
          <Tag
            text={t('contact:fastest')}
            icon={<img height={14} alt="quick watch" src="/assets/quick_watch.png" />}
          />
        </div>
        <p><Trans>{t('contact:chatWithSFDescription')}</Trans></p>
        {isChatAvailable && (
          <div className={styles.showAfterTablet}>
            <button className={styles.elementButton} aria-label={t('contact:startLiveChat')} onClick={startChat}>
              <img src="/assets/message.svg" alt="chat" />
              {t('contact:startLiveChat')}
            </button>
          </div>
        )}
        {isWhatsAppAvailable && (
          <div className={styles.elementContent}>
            <div className={styles.whatsAppSection}>
              <WhatsAppButton
                variant="customButton"
                text={t('contact:askQuestionViaWhatsApp')}
                customButtonClassName={classNames(styles.showAfterMobile, styles.elementButton)}
                regularButtonClassname={styles.whatsAppRegularButton}
              />
              <p className={styles.showAfterMobile}>{`(${stylesheet.contact.whatsappNumber})`}</p>
            </div>
            <p className={classNames(styles.showAfterMobile, styles.whatsAppDescription)}>{t('contact:whatsAppDescription')}</p>
          </div>
        )}
      </div>
      <Separator classes={classNames(isDesktopOnly && styles.hideTillMobile)} />
    </>
  );
};
