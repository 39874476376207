import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BusinessHours, Stylesheet } from '../../interfaces/Stylesheet';
import { Separator } from '../../components/Separator/Separator';
import styles from './PhoneSection.module.scss';
import { SmallFAQCallContainer } from '../../components/FAQPhoneContainer/SmallFAQCallContainer';
import { ContactChatContainer } from '../../components/ContactChatContainer/ContactChatContainer';

interface PhoneSectionProps {
  stylesheet: Stylesheet;
}

const days: (keyof BusinessHours)[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const PhoneSection: FC<PhoneSectionProps> = ({ stylesheet }) => {
  const { businessHours } = stylesheet.contact;
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Separator />
      <ContactChatContainer/>
      <div>
        <h3>{t('contact:businessHours')}</h3>
        <table>
          <tbody>
            {days.map((day) => (
              <tr key={day}>
                <td>{t(`days:${day}`)}</td>
                {!businessHours?.[day]
                  ? (<td>{t('shared:closed')}</td>)
                  : (<td>{businessHours[day]}</td>)
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Separator />
      <SmallFAQCallContainer renderFAQ={false} />
    </div>
  );
};

export default PhoneSection;
