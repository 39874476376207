export default {
  nl: {
    contact: {
      title: 'Hoe kunnen\nwe <span>helpen</span>?',
      faq: 'Veelgestelde vragen',
      faqLink: 'Bekijk ook onze veelgestelde vragen',
      contactPageLink: 'Veelgestelde vragen & contact',
      contactPageLinkDescription: 'Bekijk eerst onze veelgestelde vragen of neem contact op. We helpen je graag verder!',
      contactPageButton: 'Bekijk de pagina',
      mailTabTitle: 'Mailen',
      callTabTitle: 'Bellen',
      businessHours: 'Klantenservice openingstijden',
      invalidEmail: 'Ongeldig e-mailadres',
      loadingMessage: 'Bericht wordt verzonden ...',
      errorMessage: 'Het verzenden van het bericht is mislukt. Probeer het nogmaals of neem telefonisch contact met ons op.',
      successMessage: 'Bericht is succesvol verzonden.',
      chatWithWhatsApp: 'Direct reactie via WhatsApp!',
      chatWithWhatsAppDescription: 'Directe ondersteuning zonder wachten? Chat nu via <strong>WhatsApp</strong> met Guidion!',
      chatWithSF: 'Chat met Guidion',
      chatWithSFDescription: 'Directe ondersteuning zonder wachten?<br/>Chat nu met Guidion!',
      localTariff: 'lokaal tarief',
      fastest: 'Snelst',
      askQuestionViaWhatsApp: 'Stel je vraag via WhatsApp',
      whatsAppDescription: 'Let op, deze chat opent een andere pagina',
      startLiveChat: 'Start live chat',
      startChat: 'Start chat',
    }
  },
  en: {
    contact: {
      title: 'How can\nwe <span>help</span>?',
      faq: 'Frequently Asked Questions',
      faqLink: 'Also view our frequently asked questions',
      contactPageLink: 'Frequently asked questions & contact',
      contactPageLinkDescription: "First, check out our frequently asked questions or contact us. We'll be happy to help!",
      contactPageButton: 'View the page',
      mailTabTitle: 'Mail',
      callTabTitle: 'Call',
      businessHours: 'Customer service opening hours',
      invalidEmail: 'Invalid e-mailaddress',
      loadingMessage: 'Message is being sent ...',
      errorMessage: 'Failed to send the message. Please try again or contact us by phone.',
      successMessage: 'Message was successfully sent',
      chatWithWhatsApp: 'Immediate reaction with WhatsApp!',
      chatWithWhatsAppDescription: 'Instant support without waiting? Chat now via <strong>WhatsApp</strong> with Guidion!',
      chatWithSF: 'Chat with Guidion',
      chatWithSFDescription: 'Instant support without waiting?<br/>Chat now with Guidion!',
      localTariff: 'local tariff',
      fastest: 'Fastest',
      askQuestionViaWhatsApp: 'Ask your question via WhatsApp',
      whatsAppDescription: 'Please note, this will open a new page',
      startLiveChat: 'Start live chat',
      startChat: 'Start chat',
    }
  }
};
